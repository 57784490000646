import { type APIBlock } from "../Block"
import {
  type BlockStyles,
  decodeBlockStyles,
  encodeBlockStyle,
} from "./BlockStyles"

export enum AdditionalFieldsNamesType {
  SigningDate = "signing_date",
  Address = "address",
  Email = "email",
  Country = "country",
}

export type AdditionalFieldsType = {
  key: AdditionalFieldsNamesType | string
  isEnabled: boolean
  // below are only for Custom Values
  isCustomValue?: boolean
  id?: string
  name?: string
}

export type SignatureBlock = {
  id: string
  kind: "signature"
  grid: [1, 1] | [1, 2] | [2, 2]
  blockStyles: BlockStyles
  additionalFields: AdditionalFieldsType[]
  authRepAlignment: "left" | "indent"
}

export const DEFAULT_ADDITIONAL_FIELDS = [
  {
    key: AdditionalFieldsNamesType.SigningDate,
    isEnabled: false,
  },
  {
    key: AdditionalFieldsNamesType.Address,
    isEnabled: false,
  },
  {
    key: AdditionalFieldsNamesType.Email,
    isEnabled: false,
  },
]

export const decodeSignatureBlock = (data: APIBlock): SignatureBlock => {
  const { id, grid, additional_fields, block_styles } = data

  return {
    id,
    kind: "signature",
    grid,
    additionalFields: additional_fields || DEFAULT_ADDITIONAL_FIELDS,
    blockStyles: decodeBlockStyles(block_styles),
    authRepAlignment: data.auth_rep_alignment || "indent",
  }
}

export const encodeSignatureBlock = (data: SignatureBlock): APIBlock => {
  const { id, grid, authRepAlignment, additionalFields, blockStyles } = data

  return {
    id,
    auth_rep_alignment: authRepAlignment,
    kind: "signature",
    grid,
    additional_fields: additionalFields,
    block_styles: {
      header: encodeBlockStyle(blockStyles.header),
      signatory_name: encodeBlockStyle(blockStyles.signatoryName),
      signatory_description: blockStyles.signatoryDescription,
      name: encodeBlockStyle(blockStyles.name),
      by_line_labels: encodeBlockStyle(blockStyles.byLineLabels),
      title: blockStyles.title,
      line_styles: blockStyles.lineStyles,
    },
  }
}
