import * as React from "react"

import { ErrorClient } from "app/config/services/error-reporting"
import { http } from "helpers/http"

import { AppConfigContext } from "helpers/contexts"

declare global {
  interface Window {
    __app: { config: Window["config"]; services: {} }
    config?: {
      APP_ENV?: string
      ASK_NICELY_KEY?: string
      CUSTIFY_ID?: string
      DE_NOVO_DOMAIN?: string
      DE_NOVO_ENV?: string
      ERROR_REPORTING?: string
      FEATURES?: {
        EDIT_PAGE?: string
        SAVE_TO_NET_DOCS?: string
      }
      HIDE_SUPPORT?: string
      MAINTENANCE_MODE?: string
      NODE_ENV?: string
      ORGANIZATION_NAME?: string
      PDFTRON_FE_LICENSE?: string
      SHARE_DOWNLOAD_LINK?: string
      TEST_DRIVE?: string
      VERSION?: string
      WHATS_NEW_FLOW_ID?: string
      // SWITCHES is coming from the Django Waffle API, but we uppercase the name so it looks more
      // like a constant. Used to switch on or off entire features for all users
      SWITCHES?: {
        UPLOAD_QUEUE?: boolean
        INTERNATIONAL_ADDRESS?: boolean
        PDF_PORTFOLIO?: boolean
        SIGNING_MATRIX_REPORT?: boolean
        PLACEHOLDERS?: boolean
      }
      // Use FLAGS for org/transaction/user-specific feature flags
      FLAGS?: {
        INSTAPAGES_V2?: boolean
        ALTERNATE_DOCUMENT_NAMES?: boolean
        SHOW_SIGNING_GROUP_TAGS?: boolean
      }
    }
  }
}

export function useFetchAppConfig() {
  let [config, setConfig] = React.useState<Window["config"] | null>(null)
  let [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    if (config) {
      window.config = config
    }
  }, [config])

  React.useEffect(() => {
    if (!config && !isLoading) {
      setIsLoading(true)

      const localConfig = http("/config").then((data) => {
        setConfig((prev) => (prev ? { ...prev, ...data } : data))
      })

      Promise.allSettled([localConfig])
        .then((responses) => {
          const isAllFullfilled = responses.every(
            (r) => r.status === "fulfilled"
          )
          if (!isAllFullfilled) {
            // if any response failed, throw the first error so the catch block is hit
            throw responses.filter((r) => r.status === "rejected")[0]
          }
          setIsLoading(false)
        })
        .catch((error) => {
          setConfig({})
          setIsLoading(false)
          ErrorClient.notify(error)
        })
    }
  }, [config, isLoading])

  const fetchFeatureStatusAndUpdateConfig = () =>
    http("/feature-status").then((featureStatusData) => {
      const decodedFeatureStatusData = {
        SWITCHES: {
          UPLOAD_QUEUE: Boolean(
            featureStatusData.switches?.upload_queue?.is_active
          ),
          INTERNATIONAL_ADDRESS: Boolean(
            featureStatusData.switches?.international_address?.is_active
          ),
          PDF_PORTFOLIO: Boolean(
            featureStatusData.switches?.pdf_portfolio?.is_active
          ),
          SIGNING_MATRIX_REPORT: Boolean(
            featureStatusData.switches?.signing_matrix_report?.is_active
          ),
          PLACEHOLDERS: Boolean(
            featureStatusData.switches?.placeholders?.is_active
          ),
        },
      }
      setConfig?.((prev) =>
        prev
          ? { ...prev, ...decodedFeatureStatusData }
          : decodedFeatureStatusData
      )
    })

  return { config, setConfig, isLoading, fetchFeatureStatusAndUpdateConfig }
}

export function useAppConfig() {
  let { config, setConfig, fetchFeatureStatusAndUpdateConfig } =
    React.useContext(AppConfigContext)

  return { config, setConfig, fetchFeatureStatusAndUpdateConfig }
}
