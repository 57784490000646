import * as React from "react"
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
  useMutation,
  useInfiniteQuery,
  MutationFunction,
} from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"

import useToaster from "helpers/toaster"

const REACT_QUERY_DEVTOOLS = "ENABLED"

const identityFn = <T,>(d: T): T => d

const apiClient = new QueryClient()

export { apiClient }
export { ApiHttp } from "./api-http"

export const useAPI = useQuery

export const usePaginatedAPI = useInfiniteQuery

export const useAPIMutation = useMutation

type MutationOptionsType = {
  cacheUpdaterFn?: (responseData: any, cacheData: any) => any
  successMsg?: string
  errorMsg?: string
  onChange?: () => void
  postSuccess?: (responseData: any) => void
}

export function useOnSuccessAPIMutation(
  queryKey: string,
  mutateFn: MutationFunction<unknown, any>,
  options: MutationOptionsType = {}
) {
  let { failure, success } = useToaster()

  return useAPIMutation(mutateFn, {
    onSuccess: async (responseData) => {
      await apiClient.cancelQueries(queryKey)

      apiClient.setQueryData(
        queryKey,
        options.cacheUpdaterFn
          ? (cacheData) => options.cacheUpdaterFn?.(responseData, cacheData)
          : identityFn
      )
      if (options.successMsg) {
        success(options.successMsg)
      }
      apiClient.refetchQueries(queryKey)
    },
    onError: () => {
      if (options.errorMsg) {
        failure(options.errorMsg)
      }
    },
    ...options,
  })
}

export function useCachedAPIMutation(
  queryKey: string[],
  mutateFn: MutationFunction<unknown, any>,
  options?: MutationOptionsType
) {
  let { failure, success } = useToaster()

  return useAPIMutation(mutateFn, {
    onMutate: async (vars) => {
      await apiClient.cancelQueries(queryKey)

      let prevData = apiClient.getQueryData(queryKey)

      if (options && options.cacheUpdaterFn) {
        let cacheUpdaterFn = options.cacheUpdaterFn
          ? (cacheData: any) => options.cacheUpdaterFn?.(vars, cacheData)
          : identityFn
        apiClient.setQueryData(queryKey, cacheUpdaterFn)
      }

      if (options && options.onChange) {
        options.onChange()
      }

      return { prevData }
    },
    onError: (_, __, context) => {
      if (options && options.errorMsg) {
        failure(options.errorMsg)
      }
      apiClient.setQueryData(queryKey, context?.prevData)
    },
    onSuccess: (data) => {
      if (options && options.successMsg) {
        success(options.successMsg)
      }
      if (apiClient.getQueryData(queryKey)) {
        // Only refetch if cached data for queryKey exist
        apiClient.refetchQueries(queryKey)
      }
      options?.postSuccess?.(data)
    },
    ...options,
  })
}

type APIProviderProps = {
  children?: React.ReactNode
}

export default function APIProvider({ children }: APIProviderProps) {
  return (
    <QueryClientProvider client={apiClient}>
      {children}
      {REACT_QUERY_DEVTOOLS === "ENABLED" && (
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      )}
    </QueryClientProvider>
  )
}
