import * as React from "react"

import DefaultErrorBoundary from "./error-reporting/ErrorBoundary"
import { SERVICES } from "../load-config-service"

type TErrorClient = {
  clientType: string
  isDisabled?: boolean
  addMetadata: (name: string, args: Record<string, any>) => void
  leaveBreadcrumb: (name: string, args: Record<string, any>) => void
  notify: (error: Error) => void
  setUser: (id: string) => void
  clearUser: () => void
  addAction: (actionName: string, meta?: Record<string, any>) => void
}

const NullErrorReporter: TErrorClient = {
  clientType: "none",
  isDisabled: true,
  addMetadata() {},
  leaveBreadcrumb() {},
  notify(error) {
    console.error("ErrorClient notify:", error)
  },
  setUser() {},
  clearUser() {},
  // eslint-disable-next-line no-unused-vars
  addAction() {},
}

export let ErrorClient: TErrorClient = NullErrorReporter

interface Sentry {
  addBreadcrumb: (breadcrumb: {
    category: string
    data: Record<string, unknown>
  }) => void
  captureException: (error: Error) => void
  setContext: (name: string, args: Record<string, unknown>) => void
  setUser: (user: { id: string } | null) => void
  setTag: (name: string, value: string) => void
}
class SentryErrorClient implements TErrorClient {
  clientType = "sentry"
  sentry: Sentry

  constructor(sentry: Sentry) {
    this.sentry = sentry
    this.sentry.setTag(
      "cloud_env",
      window?.config?.ORGANIZATION_NAME || "local"
    )
  }

  addMetadata(name: string, args: Record<string, unknown>) {
    this.sentry.setContext(name, args)
  }

  leaveBreadcrumb(name: string, args: Record<string, unknown>) {
    this.sentry.addBreadcrumb({
      category: name,
      data: args,
    })
  }

  notify(error: Error) {
    this.sentry.captureException(error)
  }

  setUser(id: string) {
    this.sentry.setUser({ id })
  }
  clearUser() {
    this.sentry.setUser(null)
  }
  addAction(actionName: string) {
    console.log("Sentry addAction", actionName)
  }
}

type ErrorReporterProps = {
  children?: React.ReactNode
  FallbackComponent: React.ReactNode
}

export function ErrorReporter({ children, ...props }: ErrorReporterProps) {
  return <DefaultErrorBoundary {...props}>{children}</DefaultErrorBoundary>
}
export default function loadErrorReporting(key: keyof typeof SERVICES) {
  if (!key) return

  if (window.config && window.config.ERROR_REPORTING === "ENABLED") {
    import("@sentry/nextjs")
      .then((Sentry) => {
        if (ErrorClient.clientType === "none") {
          Sentry.init({
            dsn: "https://2313483c2dc57b224c704c72d7701c0a@o510682.ingest.us.sentry.io/4506666583719936",

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for tracing.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,

            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,

            integrations: [
              Sentry.replayIntegration({
                maskAllText: true,
                blockAllMedia: true,
                workerUrl: "/workers/sentry.worker.min.js",
              }),
            ],

            // ...

            // Note: if you want to override the automatic release value, do not set a
            // `release` value here - use the environment variable `SENTRY_RELEASE`, so
            // that it will also get attached to your source maps
          })

          ErrorClient = new SentryErrorClient(Sentry)
        }
      })
      .catch((error) => {
        console.log("Error loading Sentry...", error)
      })
  }
}
